<template>
  <div>
    <div class="section-wrapper" v-if="loaded">
      <div class="section-title" style="margin-top: 16px">
        一.资产负债情况
        <el-radio-group v-model="tabPosition">
          <el-radio-button label="表格"></el-radio-button>
          <el-radio-button label="图形"></el-radio-button>
        </el-radio-group>
      </div>
      <div class="tip" v-html="tip" v-if="tabPosition!=='表格'">
      </div>
      <div v-if="tabPosition==='表格'">
        <div class="table">
          <JindiaoGenericTable :data="generalTableData(0)" ></JindiaoGenericTable>
          <JindiaoGenericTable :data="generalTableData(1)" ></JindiaoGenericTable>
        </div>

      </div>
      <div v-else class="pile-wrapper">
        <Pile :data="this.entityJson.data[0].ruleOfInvoiceImageList[0]"
             class="pile-chart" name="资产类别"></Pile>
        <Pile :data="this.entityJson.data[1].ruleOfInvoiceImageList[0]"
              class="pile-chart" name="资金来源"></Pile>

      </div>
    </div>
  </div>
</template>

<script>
import {getDueDiligenceModel013} from "@/api/api";
import JindiaoGenericTable from "@/pages/Result/JinzhiReport/components/JindiaoGenericTable";
import Pile from "@/pages/Result/JinzhiReport/components/Pile";
import {getDesensitizationFromRouter} from "@/utils/helper";

export default {
  name: "BalanceCondition",
  components:{
    JindiaoGenericTable,
    Pile
  },
  data() {
    return {
      tabPosition: '表格',
      data: null,
      loaded: false
    }
  },
  async mounted() {
    this.loaded = false;
    const res = await getDueDiligenceModel013({uuid: this.$route.params.uuid,desensitizationStatus:getDesensitizationFromRouter()})
    this.data = res.data
    this.loaded = true
  },
  computed: {
    entityJson() {
      return this.data ? this.data.entityJson : {}
    },
    tip(){
      return this.entityJson.text&&this.entityJson.text.replace(/\n/g,"<br/>")
    },

  },
  methods:{
    //通用表格
    generalTableData(index) {
      const data = this.entityJson.data[index];
      if (!data) {
        return null;
      }

      if (!data.body) {
        return null;
      }
      const ret = [];

      for (let i = 0; i < data.body.length; i++) {
        ret[i] = [];

        data.header.forEach(key => {
          if (this.brief) {
            if (i < 5) {
              ret[i].push(data.body[i][key])
            }
          } else {

            ret[i].push(data.body[i][key])
          }
        })

      }

      return {
        data: ret,
        labels: data.header
      };
    },
  }
}
</script>

<style lang="stylus" scoped>
.section-title
  display flex;
  justify-content space-between;
  align-items center

.tip
  font-size: 14px;
  background: #F0F5FF;
  padding 16px 24px
  line-height 1.5
.pile-wrapper
  display flex;
  padding-top 50px
  .pile-chart
    width 50%
</style>
