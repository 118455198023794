<template>
  <div class="section-wrapper">
    <div class="section-title">
      一. 基本信息
    </div>
    <div class="table">
      <table>
        <tbody>
        <tr>
          <td class="solid" style="width: 196px">企业名称</td>
          <td colspan="3">{{entityJsonData.name}}</td>
        </tr>
        <tr>
          <td class="solid">营业执照号</td>
          <td
          >{{entityJsonData.creditCode}}
          </td>
          <td class="solid">组织机构代码证号</td>
          <td>{{entityJsonData.orgNumber}}</td>
        </tr>
        <tr>
          <td class="solid">法定代表人</td>
          <td
          >{{entityJsonData.legalPersonName}}
          </td>
          <td class="solid">经营状态</td>
          <td>{{entityJsonData.regStatus}}</td>
        </tr>
        <tr>
          <td class="solid">成立日期</td>
          <td
          >{{entityJsonData.estiblishTime}}
          </td>
          <td class="solid">营业期限</td>
          <td>{{entityJsonData.operationPeriod}}</td>
        </tr>
        <tr>
          <td class="solid">公司类型</td>
          <td
          >{{entityJsonData.companyOrgType}}
          </td>
          <td class="solid">登记机关</td>
          <td>{{entityJsonData.regInstitute}}</td>
        </tr>
        <tr>
          <td class="solid">注册资金</td>
          <td
          >{{entityJsonData.regCapital}}
          </td>
          <td class="solid">实收资本</td>
          <td>{{entityJsonData.actualCapital}}</td>
        </tr>
        <tr>
          <td class="solid">所属行业</td>
          <td class="mouhanye">{{entityJsonData.industry}}
            <el-tooltip v-if="entityJsonData.highPollution" class="item" effect="dark" placement="top-start">
              <div slot="content">备注：所谓重点监控（排污）纳税人，指火电、钢铁、水泥、电解铝、煤炭、冶金、建材、采矿、化工、石化、制药、轻工（酿造、造纸、发酵、制糖、植物油加<br/>工）、纺织、制革等14个重点污染行业的纳税人及其他排污行业的重点监控企业。</div>
              <div class="label" >
                高污染 <img src="@/assets/wen.png" class="wen">

              </div>
            </el-tooltip>


          </td>
          <td class="solid">社保人数</td>
          <td>{{entityJsonData.socialStaffNum}}</td>
        </tr>
        <tr>
          <td class="solid">税务登记证号</td>
          <td
          >{{entityJsonData.taxNumber}}
          </td>
          <td class="solid">纳税人资质</td>
          <td>{{entityJsonData.taxpayerQualification}}</td>
        </tr>
<!--        <tr>-->
<!--          <td class="solid">特许经营许可证号码</td>-->
<!--          <td-->
<!--            colspan="3"-->
<!--          ></td>-->
<!--        </tr>-->
        <tr>
          <td class="solid">注册地址</td>
          <td
            colspan="4">{{entityJsonData.regLocation}}
          </td>
        </tr>
        <tr>
          <td class="solid">联系地址</td>
          <td
            colspan="4">{{entityJsonData.address}}
          </td>
        </tr>
        <tr>
          <td class="solid">电话/传真</td>
          <td
            colspan="4">{{entityJsonData.phoneNumber}}
          </td>
        </tr>
        <tr>
          <td class="solid">经营范围</td>
          <td colspan="4">{{entityJsonData.businessScope}}</td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import {getDueDiligenceModel001} from "@/api/api";
import {getDesensitizationFromRouter} from "@/utils/helper";

export default {
  name: "BasisSection",
  data(){
    return{
      data:null
    }
  },
  async mounted() {
    const res = await getDueDiligenceModel001({uuid:this.$route.params.uuid,desensitizationStatus:getDesensitizationFromRouter()})
    this.data = res.data
  },
  computed:{
    entityJsonData(){
      return this.data?this.data.entityJson.data:{}
    }
  }
}
</script>

<style lang="stylus" scoped>
.mouhanye
  display flex
  justify-content space-between
  align-items center
.label
  display inline-block;
  border 1px solid #F5222D;
  border-radius 0px
  padding 2px 5px
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #F5222D;
  line-height: 20px;
  display flex
  justify-items center
  flex-grow 0
  .wen
    margin-left 5px
    width 16px;
    height 16px
    position relative
    top 2px
</style>
