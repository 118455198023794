<template>
  <div class="section-wrapper" v-if="loaded">
    <div class="section-title">
      四.{{ entityJson.name }}
    </div>
    <div class="tip" v-html="tip">
    </div>
    <Qian10daSection title="前十大销售商品" :data="entityJson.data[1].jsonData" v-if="entityJson.data[1].jsonData" :text="entityJson.data[1].text"  :chart="entityJson.data[1].jsonData.ruleOfInvoiceImageList[0]"></Qian10daSection>
    <Qian10daSection title="前十大客户" :data="entityJson.data[0].jsonData" v-if="entityJson.data[0].jsonData" :text="entityJson.data[0].text" tip="表格中标红的企业为有风险企业" :chart="entityJson.data[0].jsonData.ruleOfInvoiceImageList[0]"></Qian10daSection>
  </div>
</template>

<script>
import {getDueDiligenceModel010} from "@/api/api";
import Qian10daSection from "@/pages/Result/JinzhiReport/Qian10daSection";
import {getDesensitizationFromRouter} from "@/utils/helper";

export default {
  name: "SalesInfo.vue",
  components: {
    Qian10daSection
  },
  data() {
    return {
      data: null,
      loaded: false
    };
  },
  async mounted() {
    this.loaded = false;
    const res = await getDueDiligenceModel010({uuid: this.$route.params.uuid,desensitizationStatus:getDesensitizationFromRouter()})
    this.data = res.data
    this.loaded = true
  },
  computed: {
    entityJson() {
      return this.data ? this.data.entityJson : {}
    },
    tip(){
      return this.entityJson.text&&this.entityJson.text.replace(/\n/g,"<br/>")
    }
  },

}
</script>

<style lang="stylus" scoped>
.tip
  font-size: 14px;
  background: #F0F5FF;
  padding 16px 24px
  line-height 1.5
  margin-top 16px
.info-tip
  color: #F5222D;
  line-height: 24px;
  font-size 14px
</style>
