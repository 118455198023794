<template>
  <div class="section-wrapper" v-if="loaded">
    <div class="section-title">
      二. {{ entityJson.name }}
    </div>
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane :label="`${data.name}`" :name="key+''"
                   v-for="(data,key) in entityJson.data" :key="key">
        <PurchaseAndSaleTable v-if="key==0" :data="entityJson.data[0].data"></PurchaseAndSaleTable>
        <PurchaseTable v-if="key==1" :data="entityJson.data[1].data"></PurchaseTable>
        <PurchaseTable v-if="key==2" :data="entityJson.data[2].data"></PurchaseTable>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import {getDueDiligenceModel008} from "@/api/api";
import PurchaseTable from "@/pages/Result/JinzhiReport/AllPurchaseAndSaleMatching/PurchaseTable";
import PurchaseAndSaleTable
  from "@/pages/Result/JinzhiReport/AllPurchaseAndSaleMatching/PurchaseAndSaleTable";
import {getDesensitizationFromRouter} from "@/utils/helper";

export default {
  name: "AllPurchaseAndSaleMatching",
  components: {PurchaseTable, PurchaseAndSaleTable},

  data() {
    return {
      data: null,
      loaded: false,
      activeName: '0',
    };
  },
  async mounted() {
    this.loaded = false;
    const res = await getDueDiligenceModel008({uuid: this.$route.params.uuid,desensitizationStatus:getDesensitizationFromRouter()})
    this.data = res.data
    this.loaded = true
  },
  computed: {
    entityJson() {
      return this.data ? this.data.entityJson : {}
    }
  },
  methods: {
    handleClick(tab, event) {
      //console.log(tab, event);
    }
  }
}
</script>

<style scoped>

</style>
