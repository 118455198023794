<template>
  <div class="section-wrapper" v-if="loaded">
    <div class="section-title">
      四. {{ entityJson.name }}
    </div>
    <div class="table">
      <table>
        <tbody>
        <tr>
          <th :rowspan="entityJson.data[0].body.length+1" style="width: 208px;">股东情况</th>
          <th  style="width: 368px;">股东全称</th>
          <th  style="width: 336px;">认缴出资金额</th>
          <th  style="width: 288px;">持股比例</th>
        </tr>
        <tr v-for="(data,index) in entityJson.data[0].body" :key="index">
          <td>{{data['股东全称']}}</td>
          <td>{{data['认缴出资金额']}}</td>
          <td>{{data['持股比例']}}</td>
        </tr>
        <tr>
          <td
            :rowspan="entityJson.data[1].body.length+1" class="solid" style="width: 208px;">主要管理人员
          </td>
          <td class="solid">职务</td>
          <td colspan="2" class="solid">姓名</td>
        </tr>
        <tr v-for="(data,index) in entityJson.data[1].body" :key="index+'a'">
          <td>{{data['职务']}}</td>
          <td
            colspan="2">{{data['姓名']}}
          </td>
        </tr>

        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import {getDueDiligenceModel004} from "@/api/api";
import {getDesensitizationFromRouter} from "@/utils/helper";

export default {
  name: "ShareHolderAndManagementInfo",
  data() {
    return {
      loaded:false
    };
  },
  async mounted() {
    this.loaded = false;
    const res = await getDueDiligenceModel004({uuid: this.$route.params.uuid,desensitizationStatus:getDesensitizationFromRouter()})
    this.data = res.data
    this.loaded = true
  },
  computed: {
    entityJson() {
      return this.data ? this.data.entityJson : {}
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style lang="stylus" scoped>
.table{
  table-layout fixed
  tr{
    td{
      text-align center
    }
  }
}
</style>
