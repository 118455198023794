<template>
  <div>
    <JindiaoTable
      v-if="generalTableData"
      :data="generalTableData"
      mode="more"
    ></JindiaoTable>
<!--    <template v-if="dataBodyLength>5">-->
<!--      <button class="btn btn-default btn-more" v-if="brief" @click="brief=false">-->
<!--        <i class="el-icon-arrow-down"></i> 内容展开-->
<!--      </button>-->
<!--      <button class="btn btn-default btn-more" v-else @click="brief=true">-->
<!--        <i class="el-icon-arrow-up"></i> 内容收起-->
<!--      </button>-->
<!--    </template>-->
  </div>
</template>

<script>
import JindiaoTable from "@/pages/Result/JinzhiReport/components/JindiaoGenericTable";

export default {
  name: "MoreTable",
  components: {
    JindiaoTable
  },
  data() {
    return {
      brief: true
    }
  },
  props: {
    data: Object,
  },
  computed: {
    //通用表格
    generalTableData() {

      if (!this.data) {
        return null;
      }

      if (!this.data.body) {
        return null;
      }
      const ret = [];

      for (let i = 0; i < this.data.body.length; i++) {
        ret[i] = [];

        this.data.header.forEach(key => {
          ret[i].push(this.data.body[i][key])
        })

      }

      return {
        data: ret,
        labels: this.data.header
      };
    },
    dataBodyLength(){
      return this.data?this.data.body.length:0
    }
  }
}
</script>

<style lang="stylus" scoped>
.btn.btn-more
  margin 16px auto
  display block
  width: 400px;
  background: rgba(255, 255, 255, 0.26);
  border: 1px solid #061178;
  color: #061178 !important;

.btn.btn-more:hover {
  color #fff !important;
  background #2f54eb !important;
}
</style>
