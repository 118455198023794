<template>
  <div class="wrapper">
    <div class="chart-wrapper">
      <PileChart :colors="colors" :data="chartData" :name="name"></PileChart>
      <div class="name">{{name}}</div>
    </div>

    <div class="legends">
      <div class="legend" v-for="(item,index) in chartData" :key="index">
        <div class="title">
          <div class="box" :style="{'background':colors[index%colors.length]}"></div>
          {{ item.name }}
        </div>
        <div class="value">{{getPercent(item.value)|percentage}}</div>
      </div>
    </div>
  </div>
</template>

<script>

import PileChart from "@/pages/Result/JinzhiReport/components/PileChart";

export default {
  name: 'Pile',
  props: {
    name:String,
    data: Object,
    colors: {
      type: Array,
      default() {
        return [
          "#EC5757",
          "#BCA88D",
          "#2588ED",
          "#F7A659",
          "#95A5CD",
          "#FF409A",
          "#B8D4F9",
          "#ABDE8A",
          "#EED9BE",
          "#6293F8",
          "#878787"
        ]
      }
    }
  },
  components: {
    PileChart
  },
  computed: {
    chartData() {
      const ret = [];
      for (const i in this.data.xLabels) {
        const label = this.data.xLabels[i];
        const value = this.data.series[0].data[i];
        ret.push({name: label, value});
      }
      return ret
    }
  },
  methods:{
    getPercent(value){
      const total = this.chartData.reduce((acc,item)=>{
        return acc+parseFloat(item.value)
      },0)
      const ret = value/total
      return ret;
    }
  }
};
</script>

<style lang="stylus" scoped>
.wrapper {
  display: flex;
  width: 100%;
  height: 362px;

  .chart-wrapper {
    height: 362px;
    display: flex;
    align-items: center;
    flex-direction column

    .name{
      position relative;
      left 15px
    }
  }

  .legends {
    height: 362px;
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0px;

    .legend {
      width: 80%;
      color: #666;
      display: flex;
      justify-content: space-between;
      vertical-align: top;
      margin 6px 0;
      font-size 13px
      align-items center

      .box {
        display: inline-block;
        width: 10px;
        height: 18px;
        background: red;
        margin-right: 5px;
      }

      .title {
        vertical-align: top;
        display flex
        align-items center
      }

      .value {
        vertical-align: top;
      }
    }
  }
}
</style>
