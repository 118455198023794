<template>
  <div>
    <div class="section-wrapper" v-if="loaded">
      <div class="section-title">
        二.{{ entityJson.name }}
      </div>
      <div class="table">
        <MoreTable :data="entityJson.data" :tips="this.data.tips"></MoreTable>

      </div>
    </div>
  </div>
</template>

<script>
import JindiaoGenericTable from "@/pages/Result/JinzhiReport/components/JindiaoGenericTable";
import {getDueDiligenceModel014} from "@/api/api";
import {getDesensitizationFromRouter} from "@/utils/helper";
import MoreTable from "@/pages/Result/JinzhiReport/components/MoreTable";

export default {
  name: "RevenueCostProfitChangeCondition",
  components:{
    MoreTable
  },
  async mounted() {
    this.loaded = false;
    const res = await getDueDiligenceModel014({uuid: this.$route.params.uuid,desensitizationStatus:getDesensitizationFromRouter()})
    this.data = res.data
    this.loaded = true
  },
  data() {
    return {
      data: null,
      loaded: false
    }
  },
  computed: {
    entityJson() {
      return this.data ? this.data.entityJson : {}
    },
    tip(){
      return this.entityJson.text&&this.entityJson.text.replace(/\n/g,"<br/>")
    },
    //通用表格
    generalTableData() {
      const data = this.entityJson.data;
      if (!data) {
        return null;
      }

      if (!data.body) {
        return null;
      }
      const ret = [];

      for (let i = 0; i < data.body.length; i++) {
        ret[i] = [];

        data.header.forEach(key => {
          if (this.brief) {
            if (i < 5) {
              ret[i].push(data.body[i][key])
            }
          } else {

            ret[i].push(data.body[i][key])
          }
        })

      }

      return {
        body: ret,
        header: data.header
      };
    },
  },
}
</script>

<style lang="stylus" scoped>
>>>.scroller
  min-height 90vh
</style>
