<template>
  <div>
    <div class="section-wrapper" v-if="loaded">
      <div class="section-title">
        五. {{ entityJson.name }}
      </div>
      <div class="table">
        <MoreTable :data="entityJson.data" ></MoreTable>
      </div>
    </div>
  </div>
</template>

<script>
import {getDueDiligenceModel005} from "@/api/api";
import MoreTable from "@/pages/Result/JinzhiReport/components/MoreTable";
import {getDesensitizationFromRouter} from "@/utils/helper";

export default {
  name: "OutboundInvestment",
  components:{
    MoreTable
  },
  data() {
    return {
      loaded:false
    };
  },
  async mounted() {
    this.loaded = false;
    const res = await getDueDiligenceModel005({uuid: this.$route.params.uuid,desensitizationStatus:getDesensitizationFromRouter()})
    this.data = res.data
    this.loaded = true
  },
  computed: {
    entityJson() {
      return this.data ? this.data.entityJson : {}
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style scoped>

</style>
