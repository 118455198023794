<template>
  <div>
    <div class="section-wrapper" v-if="loaded">
      <div class="section-title">
        六. {{ entityJson.name }}
      </div>
      <div class="table">
        <ChangeLogMoreTable :data="entityJson.data"></ChangeLogMoreTable>
      </div>
    </div>
  </div>
</template>

<script>
import {getDueDiligenceModel006} from "@/api/api";
import ChangeLogMoreTable
  from "@/pages/Result/JinzhiReport/ChangeLog/components/ChangeLogMoreTable";
import {getDesensitizationFromRouter} from "@/utils/helper";

export default {
  name: "Changelog",
  components: {
    ChangeLogMoreTable
  },
  data() {
    return {
      loaded: false,
      filters: [{
        col: 2,
        options: [{
          label: '全部类型', f: function (value) {
            return true
          }
        }, {
          label: '企业名称', f: function (value) {
            return value === '企业名称'
          }
        }, {
          label: '注册资本', f: function (value) {
            return value === '注册资本'
          }
        }]
      }]
    };
  },
  async mounted() {
    this.loaded = false;
    const res = await getDueDiligenceModel006({uuid: this.$route.params.uuid,desensitizationStatus:getDesensitizationFromRouter()})
    this.data = res.data
    this.loaded = true
  },
  computed: {
    entityJson() {
      return this.data ? this.data.entityJson : {}
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style scoped>

</style>
