<template>
  <div class="body-wrapper">
    <div class="jinzhi-report-wrapper">
        <div class="head-wrapper">
          <div class="title-wrapper">
            <div class="title">{{data.companyName}}</div>
            <div class="sub-title">尽职调查报告</div>
            <div class="date">报告日期：{{data.createTime}}</div>
          </div>
        </div>
      <BlockTitle>
        企业基本情况
      </BlockTitle>
      <BasisSection></BasisSection>
      <PublicPositiveInformation></PublicPositiveInformation>
      <RiskWarning></RiskWarning>
      <ShareHolderAndManagementInfo></ShareHolderAndManagementInfo>
      <OutboundInvestment></OutboundInvestment>
      <ChangeLog></ChangeLog>
      <BlockTitle>
        企业经营情况
      </BlockTitle>
      <div class="warning-tip">
        本部分内容数据来自{{data.zqSection}}该企业纳税申报数据，包括发票、纳税申报表、财务报表。
      </div>
      <AllPurchaseAndSaleLine></AllPurchaseAndSaleLine>
      <AllPurchaseAndSaleMatching></AllPurchaseAndSaleMatching>
      <PurchaseInfo></PurchaseInfo>
      <SalesInfo></SalesInfo>
      <TaxCondition></TaxCondition>
      <KeyCostCondition></KeyCostCondition>
      <BlockTitle>
        企业财务情况
      </BlockTitle>
      <div class="warning-tip">
        本部分内容数据来自{{data.zqSection}}该企业纳税申报数据，包括发票、纳税申报表、财务报表。
      </div>
      <BalanceCondition></BalanceCondition>
      <RevenueCostProfitChangeCondition></RevenueCostProfitChangeCondition>
      <div class="tail">本报告由税安科技（杭州）有限公司提供，本报告仅供参考，不得用作法律诉讼的依据。未经税安科技同意或授权，不得向第三方透露本报告任何内容。在任何情况下，对由于使用本报告所造成的损失，税安科技不承担任何责任。</div>
    </div>
    <JindiaoShowShareCode v-if="!$route.name.includes('share')" ref="ShowShareCode" :companyName="data.companyName" :uuid="programId"></JindiaoShowShareCode>
  </div>
</template>

<script>
import BlockTitle from "@/pages/Result/components/BlockTitle";
import BasisSection from "@/pages/Result/JinzhiReport/BasisSection";
import PublicPositiveInformation
  from "@/pages/Result/PublicPositiveInformation/PublicPositiveInformation";
import RiskWarning from "@/pages/Result/JinzhiReport/RiskWarning";
import ShareHolderAndManagementInfo from "@/pages/Result/JinzhiReport/ShareHolderAndManagementInfo";
import OutboundInvestment from "@/pages/Result/JinzhiReport/OutboundInvestment";
import ChangeLog from "@/pages/Result/JinzhiReport/ChangeLog/ChangeLog";
import AllPurchaseAndSaleLine from "@/pages/Result/JinzhiReport/AllPurchaseAndSaleLine";
import AllPurchaseAndSaleMatching from "@/pages/Result/JinzhiReport/AllPurchaseAndSaleMatching/AllPurchaseAndSaleMatching";
import PurchaseInfo from "@/pages/Result/JinzhiReport/PurchaseInfo";
import SalesInfo from "@/pages/Result/JinzhiReport/SalesInfo";
import TaxCondition from "@/pages/Result/JinzhiReport/TaxCondition";
import KeyCostCondition from "@/pages/Result/JinzhiReport/KeyCostCondition";
import BalanceCondition from "@/pages/Result/JinzhiReport/BalanceCondition/BalanceCondition";
import RevenueCostProfitChangeCondition
  from "@/pages/Result/JinzhiReport/RevenueCostProfitChangeCondition";
import { getResultBasicInfo} from "@/api/api";
import JindiaoShowShareCode from "@/pages/Result/JinzhiReport/JindiaoShowShareCode";
import {getDesensitizationFromRouter} from "@/utils/helper";

export default {
  name: "JinzhiReport",
  components: {
    JindiaoShowShareCode,
    BlockTitle,
    BasisSection,
    PublicPositiveInformation,
    RiskWarning,
    ShareHolderAndManagementInfo,
    OutboundInvestment,
    ChangeLog,
    AllPurchaseAndSaleLine,
    AllPurchaseAndSaleMatching,
    PurchaseInfo,
    SalesInfo,
    TaxCondition,
    KeyCostCondition,
    BalanceCondition,
    RevenueCostProfitChangeCondition
  },
  data() {
    return {
      data: {},
      loaded: false
    };
  },
  async mounted() {
    this.loaded = false;
    const res = await getResultBasicInfo({uuid: this.$route.params.uuid,desensitizationStatus:getDesensitizationFromRouter()})
    this.data = res.data
    this.loaded = true
  },
  computed:{
    programId(){
      return this.$route.params.uuid
    }
  }

}
</script>

<style lang="stylus" scoped>
.body-wrapper
  background: #fbfbfb;
.jinzhi-report-wrapper
  text-align justify;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  padding 10px 0
  border-radius: 0px;
  width 1440px;
  padding 50px 120px 50px;
  box-sizing border-box
  margin 0px auto;
  background #fff;

  .head-wrapper
    display flex
    align-items center;
    justify-content center;
    margin-top 80px
    position relative;

    .title-wrapper
      text-align center;

      .title
        color #282828
        font-size: 40px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.7);
        line-height: 59px;
        letter-spacing: 4px;
        text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);

      .sub-title
        margin-top 20px
        font-size: 20px;
        text-align center;
        color #666

      .date
        font-size: 14px;
        margin-top 20px
        color #999
        text-align center


    .link-view-body
      color #207DFB
      position absolute;
      right 20px;
      top 50%;
      font-size 24px
>>>.el-tabs__header
  margin-bottom 0
>>>.section-wrapper
  margin-bottom 40px
>>>.el-radio-group
    .el-radio-button__inner
      padding 6px 23px
      line-height 1.5
      color rgba(0, 0, 0, 0.4);
    .el-radio-button__orig-radio:checked+.el-radio-button__inner
      border: 1px solid #061178;
      background #fff
      color rgba(6, 17, 120, 1)
      box-shadow none
>>>.section-title
  padding 0 24px;
  box-sizing border-box
  width: 100%
  height: 64px;
  display flex;
  align-items center
  text-align center
  background: #061178;
  border-radius: 0px;
  color: #FFFFFF !important
  font-size: 24px;
  line-height: 32px;
>>>.tableTop
  background: #D6E4FF;
  padding 14px 26px
  color: #030852;

>>>.table
  width 100%
  border-radius 0px
  margin-top 16px
  overflow hidden
  table
    border-top 1px solid #D9D9D9;
    border-left 1px solid #D9D9D9;
    margin-bottom 16px
    width 100%;
    border-spacing 0;
    th{
      text-align center
      white-space:nowrap;
    }
    th,.solid{
      background: #F0F5FF;
    }
    .center{
      text-align center
    }
    td,th{

      line-height 1.5
      border-bottom 1px solid #D9D9D9;
      border-right 1px solid #D9D9D9;

      padding 16px
      box-sizing border-box
      font-weight: 400;
      font-size: 14px;
    }
    td{
      background: #FBFBFB;
    }
>>>.el-tabs--card>.el-tabs__header .el-tabs__nav
  display flex
  align-items flex-start
  border 0 none;
>>>.el-tabs--card>.el-tabs__header .el-tabs__item{
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  text-align center
  width 146px;
  border-left 0 none
  padding 16px 24px
  font-weight: 500;
  height auto;
  line-height 1.5
}
>>>.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
  color: #10239E;
  border-bottom  2px solid #10239E
  height auto;
  line-height 1.5
  font-size 16px
}
>>>.el-tabs--card>.el-tabs__header .el-tabs__item.is-active:focus{
  box-shadow none
}
.warning-tip
  background: rgba(250, 173, 20, 0.1);
  border: 1px solid #C08000;
  font-weight: 400;
  color: #C08000;
  padding 24px
  line-height: 24px;
  margin-bottom 40px

.tail
  font-size: 16px;
  font-weight: 400;
  color: #8B8B8B;
  line-height: 24px;
  letter-spacing: 1px;
  position relative
  top -16px

>>>.btn-more:hover{
  color #fff !important;
  background #2f54eb !important;
}

</style>
