<template>
  <div class="more">
    <div v-if="isMoreThanStandard" class="float-table">
      <table>
        <thead>
        <tr>
          <th v-for="(label, index) in labels" :key="index" :style="guessStyle(label,index)">

            <template v-if="label==='变更类型'">
              <el-dropdown @command="handleTypeChange">
              <span class="el-dropdown-link">
                {{ label }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item,index) in filterOptions" :key="index"
                                    :command="item.value">{{ item.label }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
            <template v-else>
              {{
                label
              }}
            </template>

          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in tableData" :key="index">
          <td v-for="(col, index2) in row" :key="index2" @click="handleTdClick"
              :style="guessTdStyle(col,index2)">
            <el-tooltip class="item" effect="light" :content="tipText(index,index2)"
                        placement="top-start"
                        :disabled="!tipText(index,index2)">
            <span v-if="col == 'button{detail}'"
                  class="clickable"
                  @click="handleCellClick(index)"
                  :class="{'text-has-tip':tipText(index,index2)}"
            ><a
              href="#"
              @click.prevent="handleToggleDetailCLick"
              class="link"
            >{{ toggleLabel }}</a
            ></span
            >
              <span v-else v-html="col"
                    :class="{'text-has-tip':tipText(index,index2),'text-has-table':!!subTable(index,index2)}"
                    @click="handleSubTableLinkClick(subTable(index,index2),index,index2)"></span>
            </el-tooltip>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="scroller" ref="scroller">
      <table>
        <thead>
        <tr>
          <th v-for="(label, index) in labels" :key="index" :style="guessStyle(label,index)">

            <template v-if="label==='变更类型'">
              <el-dropdown @command="handleTypeChange">
              <span class="el-dropdown-link">
                {{ label }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item,index) in filterOptions" :key="index"
                                    :command="item.value">{{ item.label }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
            <template v-else>
              {{
                label
              }}
            </template>

          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in tableData" :key="index">
          <td v-for="(col, index2) in row" :key="index2" @click="handleTdClick"
              :style="guessTdStyle(col,index2)">
            <el-tooltip class="item" effect="light" :content="tipText(index,index2)"
                        placement="top-start"
                        :disabled="!tipText(index,index2)">
            <span v-if="col == 'button{detail}'"
                  class="clickable"
                  @click="handleCellClick(index)"
                  :class="{'text-has-tip':tipText(index,index2)}"
            ><a
              href="#"
              @click.prevent="handleToggleDetailCLick"
              class="link"
            >{{ toggleLabel }}</a
            ></span
            >
              <span v-else v-html="col"
                    :class="{'text-has-tip':tipText(index,index2),'text-has-table':!!subTable(index,index2)}"
                    @click="handleSubTableLinkClick(subTable(index,index2),index,index2)"></span>
            </el-tooltip>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import {isNumber} from "lodash";

export default {
  name: 'ChangeLogTable',
  props: {
    data: Object,
    drawerTriggerIndex: Number,
    showDetails: Boolean,
    tips: Array,
    subTables: Array,
    filters: {
      type: Object,
      default: () => {
      }
    },
    filterOptions: Array
  },
  computed: {
    isMoreThanStandard(){
      return this.data.data.length>5
    },
    tableData() {
      return this.data.data;
    },
    labels() {
      return this.data.labels;
    },
    toggleLabel() {
      return this.showDetails ? "收起" : "展开";
    },
    //通用表格
    generalTableData() {
      if (!this.subTableInDrawer) {
        return null;
      }
      const ret = [];
      for (let i = 0; i < this.subTableInDrawer.detailedTable.body.length; i++) {
        ret[i] = [];

        this.subTableInDrawer.detailedTable.header.forEach(key => {

          ret[i].push(this.subTableInDrawer.detailedTable.body[i][key])
        })

      }

      return {
        data: ret,
        labels: this.subTableInDrawer.detailedTable.header,
      };
    },
  },
  data() {
    return {
      drawer: false,
      subTableInDrawer: null,
      filterValue: ''
    };
  },
  methods: {
    handleTypeChange(e) {
      this.$emit("filterTypeChange", e)
    },
    guessTdStyle(value, index) {
      if (value == null) {
        return {};
      }
      let ret = {}
      value = value.toString().replace(/%$/)
      if (index == 0) {
        ret = {
          'text-align': 'center'
        }
      } else if (!isNaN(value)) {
        ret = {
          'text-align': 'right'
        }
      } else {
        ret = {
          'text-align': 'justify'
        }
      }
      return ret
    },
    guessStyle(label) {
      let ret = {
        'max-width': '300px'
      }
      if (label.includes('序号')) {
        ret = {width: '70px', 'text-align': 'center'}
      } else if (label.includes('日期')) {
        ret = {width: '100px'}
      } else if (label.includes('地区')) {
        ret = {width: '100px'}
      } else if (label.includes('信息类型')) {
        ret = {width: '100px'}
      } else if (label.includes('变更类型')) {
        ret = {width: '200px'}
      }
      return ret
    },
    handleTdClick() {

    },
    handleSubTableLinkClick(tableData, index, index2) {
      if (!tableData) {
        return
      }
      this.subTableInDrawer = tableData
      this.drawer = true
    },
    handleClose() {
      this.drawer = false
    },
    //点击打开表格
    subTable(row, col) {
      let ret = null;
      if (!this.subTables) {
        return null
      }
      const table = this.subTables.find(v => {
          let isShowTipInRow = false
          if (v.pos[0] == -1 || v.pos[0] == row) {
            isShowTipInRow = true
          }


          let isShowTipInCol = false
          if (v.pos[1] == -1 || v.pos[1] == col) {
            isShowTipInCol = true
          }
          if (isShowTipInRow && isShowTipInCol) {

            // debugger
          }
          return isShowTipInRow && isShowTipInCol
        }
      )
      if (table) {
        ret = {
          detailedTable: table.detailedTable,
          name: table.name
        }
      }

      return ret
    },
    //需要提示的文案
    tipText(row, col) {
      let ret = null;
      if (!this.tips) {
        return null
      }
      const tip = this.tips.find(v => {
          let isShowTipInRow = false
          if (v.pos[0] == -1 || v.pos[0] == row) {
            isShowTipInRow = true
          }


          let isShowTipInCol = false
          if (v.pos[1] == -1 || v.pos[1] == col) {
            isShowTipInCol = true
          }

          return isShowTipInRow && isShowTipInCol
        }
      )
      if (tip) {
        ret = tip.text
      }
      return ret
    },
    handleCellClick(row) {
      this.$emit("cellClick", row);
    }
    ,
    handleToggleDetailCLick() {
      this.$emit("update:showDetails", !this.showDetails);
    }
    ,

  },
}
;
</script>

<style lang="stylus" scoped>
.more {
  margin-bottom 20px
  position relative;

  .float-table {
    padding-right 7px
    box-sizing border-box
    position absolute
    height 54px
    overflow hidden
    z-index 99
    width 100%
  }

  .scroller {
    max-height 350px
    overflow-y auto
  }

}

table {
  border-collapse:collapse
  margin-bottom 0 !important
}

.fixed {
  position absolute
  z-index 2
  width 100%
}

td {
  line-height 1.2
}

td:first-child {
  text-align center
}
</style>
