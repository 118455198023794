<template>
  <div>
    <ChangeLogTable
      v-if="generalTableData"
      :data="limitedData"
      :filterOptions="filterOptions"
      @filterTypeChange="filterTypeChange"
    ></ChangeLogTable>

  </div>
</template>

<script>

import ChangeLogTable from "@/pages/Result/JinzhiReport/ChangeLog/components/ChangeLogTable";

export default {
  name: "ChangeLogMoreTable",
  components: {
    ChangeLogTable
  },
  data() {
    return {
      brief: true,
      limit:5,
      filterWord:''
    }
  },
  mounted() {
  },
  props: {
    data: Object,
  },
  computed: {
    limitedData(){
      let data = this.filteredData.data;


      return {
        labels:this.filteredData.labels,
        data
      }
    },
    filteredData(){
      let data = this.generalTableData.data;
      if(this.filterWord){
        data = data.filter(v=>v[2]===this.filterWord)
      }

      return {
        labels:this.generalTableData.labels,
        data
      }
    },
    filterOptions() {
      const existed = [];
      let options = this.generalTableData.data.map(v => {
        return v[2]
      })
      options = [...new Set(options)]
      options = options.filter(v => {
        if (v != null && v.trim()) {
          return true
        } else {
          return false
        }
      }).map(v => {
        return {
          label: v,
          value: v
        }
      })
      return [{
        label:'全部类型',
        value:''
      },...options]
    },

    //通用表格
    generalTableData() {

      if (!this.data) {
        return null;
      }

      if (!this.data.body) {
        return null;
      }
      const ret = [];

      for (let i = 0; i < this.data.body.length; i++) {
        ret[i] = [];

        this.data.header.forEach(key => {
          ret[i].push(this.data.body[i][key])
        })

      }

      return {
        data: ret,
        labels: this.data.header
      };
    },
    dataBodyLength() {
      return this.limitedData ? this.limitedData.length>this.max : 0
    }
  },
  methods:{
    filterTypeChange(value){
      this.filterWord = value
    }
  }
}
</script>

<style lang="stylus" scoped>
.btn.btn-more
  margin 16px auto
  display block
  width: 400px;
  background: rgba(255, 255, 255, 0.26);
  border: 1px solid #061178;
  color: #061178 !important;

.btn.btn-more:hover {
  color #fff !important;
}
</style>
