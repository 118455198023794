import { render, staticRenderFns } from "./PileChart.vue?vue&type=template&id=5bc6a263&scoped=true"
import script from "./PileChart.vue?vue&type=script&lang=js"
export * from "./PileChart.vue?vue&type=script&lang=js"
import style0 from "./PileChart.vue?vue&type=style&index=0&id=5bc6a263&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bc6a263",
  null
  
)

export default component.exports