<template>
  <div class="section-wrapper" v-if="loaded">
    <div class="section-title">
      三. {{ entityJson.name }}
    </div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane :label="`${data.name}(${data.total})`" :name="key+''"
                   v-for="(data,key) in entityJson.data" :key="key">
        <div class="table">
          <div class="tableTop">{{ data.name }}（{{ data.total }}）</div>
          <template v-if="Array.isArray(data.data)">
            <MoreTable :data="data2.data"  v-for="(data2,key2) in data.data" :key="key2"></MoreTable>
          </template>
          <template v-else>
            <MoreTable :data="data.data"></MoreTable>
          </template>
        </div>
      </el-tab-pane>
      <el-tab-pane label="全部显示" :name="'-1'" :key="-1">
        <div class="table" v-for="(data,key) in entityJson.data" :key="key">
          <div class="tableTop">{{ data.name }}（{{ data.total }}）</div>
          <template v-if="Array.isArray(data.data)">
            <MoreTable :data="data2.data"  v-for="(data2,key2) in data.data" :key="key2"></MoreTable>
          </template>
          <template v-else>
            <MoreTable :data="data.data"></MoreTable>
          </template>
        </div>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import MoreTable from "@/pages/Result/JinzhiReport/components/MoreTable";
import {getDueDiligenceModel003} from "@/api/api";
import {getDesensitizationFromRouter} from "@/utils/helper";

export default {
  name: "PublicPositiveInformation",
  components: {
    MoreTable
  },
  data() {
    return {
      activeName: '0',
      data: null,
      loaded: false
    };
  },
  async mounted() {
    this.loaded = false;
    const res = await getDueDiligenceModel003({uuid: this.$route.params.uuid,desensitizationStatus:getDesensitizationFromRouter()})
    this.data = res.data
    this.loaded = true
  },
  computed: {
    entityJson() {
      return this.data ? this.data.entityJson : {}
    }
  },
  methods: {
    handleClick(tab, event) {
      //console.log(tab, event);
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
