<template>
  <div>
    <div class="section-wrapper" v-if="loaded">
      <div class="section-title">
        一. {{ entityJson.name }}
      </div>

      <Column2Chart class="column2Chart" :chartData="entityJson.data"></Column2Chart>
    </div>
  </div>
</template>

<script>
import {getDueDiligenceModel007} from "@/api/api";
import Column2Chart from "@/pages/Result/JinzhiReport/components/Column2Chart";
import {getDesensitizationFromRouter} from "@/utils/helper";

export default {
  components:{
    Column2Chart
  },
  name: "AllPurchaseAndSaleLine",
  data() {
    return {
      data: null,
      loaded: false
    };
  },
  async mounted() {
    this.loaded = false;
    const res = await getDueDiligenceModel007({uuid: this.$route.params.uuid,desensitizationStatus:getDesensitizationFromRouter()})
    this.data = res.data
    this.loaded = true
  },
  computed: {
    entityJson() {
      return this.data ? this.data.entityJson : {}
    }
  },
  methods: {
    handleClick(tab, event) {
      //console.log(tab, event);
    }
  }
}
</script>

<style lang="stylus" scoped>
.column2Chart
  width: 100%;
  height: 300px;
  margin-top 16px
</style>
