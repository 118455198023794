<template>
  <div class="section-wrapper" v-if="loaded">
    <div class="section-title">
      五.{{ entityJson.name }}
    </div>
    <TaxBasis :data="entityJson.data.taxModelResult" :text="entityJson.text" name="纳税基本情况"></TaxBasis>
    <TaxRiskEvaluate :data="entityJson.data.pdfCatalogsList"></TaxRiskEvaluate>
  </div>
</template>

<script>
import TaxBasis from "@/pages/Result/JinzhiReport/TaxBasis";
import TaxRiskEvaluate from "@/pages/Result/JinzhiReport/TaxRiskEvaluate";
import {getDueDiligenceModel011} from "@/api/api";
import {getDesensitizationFromRouter} from "@/utils/helper";

export default {
  name: "RiskWarning",
  components:{
    TaxBasis,
    TaxRiskEvaluate
  },
  data() {
    return {
      activeName: '0',
      data: null,
      loaded: false
    };
  },
  async mounted() {
    this.loaded = false;
    const res = await getDueDiligenceModel011({uuid: this.$route.params.uuid,desensitizationStatus:getDesensitizationFromRouter()})
    this.data = res.data
    this.loaded = true
  },
  computed: {
    entityJson() {
      return this.data ? this.data.entityJson : {}
    },
    tip(){
      return this.entityJson.text&&this.entityJson.text.replace(/\n/g,"<br/>")
    }
  },
}
</script>

<style scoped>

</style>
