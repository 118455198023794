<template>
  <JindiaoTable
    v-if="generalTableData"
    :data="generalTableData"
  ></JindiaoTable>
</template>

<script>
import JindiaoTable from "@/pages/Result/JinzhiReport/components/JindiaoGenericTable";

export default {
  name: "OriginalTable",
  components:{
    JindiaoTable
  },
  props:{
    data:Object
  },
  computed:{
    //通用表格
    generalTableData() {

      if (!this.data) {
        return null;
      }

      if (!this.data.body) {
        return null;
      }
      const ret = [];

      for (let i = 0; i < this.data.body.length; i++) {
        ret[i] = [];

        this.data.header.forEach(key => {

          ret[i].push(this.data.body[i][key])
        })

      }

      return {
        data: ret,
        labels: this.data.header
      };
    },
  }
}
</script>

<style scoped>

</style>
