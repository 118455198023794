<template>
  <div>
    <div class="section-wrapper">
      <div class="tableTop">
        {{title}}
        <el-radio-group v-model="tabPosition">
          <el-radio-button label="表格"></el-radio-button>
          <el-radio-button label="环形图"></el-radio-button>
        </el-radio-group>
      </div>
      <div class="tip" v-if="text">{{text}}</div>
      <div v-if="tabPosition==='表格'">

        <div class="table">

          <JindiaoGenericTable :data="generalTableData" :tips="data.tips"></JindiaoGenericTable>
        </div>
        <div class="info-tip" v-if="tip">{{tip}}</div>
      </div>
      <div v-else>
        <Pie :data="chart"
             class="pie"></Pie>
      </div>
    </div>
  </div>
</template>

<script>
import JindiaoGenericTable from "@/pages/Result/JinzhiReport/components/JindiaoGenericTable";
import Pie from "@/pages/Result/JinzhiReport/components/Pie";

export default {
  name: "Qian10daCaigou",
  components: {
    JindiaoGenericTable,
    Pie
  },

  data() {
    return {
      tabPosition: '表格'
    }
  },
  props: {
    text:String,
    tip:String,
    title:String,
    data: Object,
    chart:Object
  },
  computed: {
    //通用表格
    generalTableData() {

      if (!this.data) {
        return null;
      }

      if (!this.data.body) {
        return null;
      }
      const ret = [];

      for (let i = 0; i < this.data.body.length; i++) {
        ret[i] = [];

        this.data.header.forEach(key => {
          if (this.brief) {
            if (i < 5) {
              ret[i].push(this.data.body[i][key])
            }
          } else {

            ret[i].push(this.data.body[i][key])
          }
        })

      }

      return {
        data: ret,
        labels: this.data.header
      };
    },
  }
}
</script>

<style lang="stylus" scoped>
div.section-wrapper
  border-radius 0px
  overflow hidden
  margin-bottom 0
  margin-top 16px
  .tableTop
    font-size: 16px;
    margin-top 0
    font-weight: 500;
    color: #061178;
    display flex;
    justify-content space-between;
    align-items center
  .table
    margin-top 0
    border-radius 0
  .pie
    width 733px !important;
    margin 10px auto;
.info-tip
  color: #F5222D;
  line-height: 24px;
  font-size 14px
.tip
  font-size: 14px;
  background: #F0F5FF;
  padding 16px 24px
  line-height 1.5
  margin-top 0px
</style>
